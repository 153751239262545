import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import io from 'socket.io-client';
import PrivateRoute from './PrivateRoute';
import config  from "./config/config";
import { AuthContext } from "./context/auth";
import { PresentialContext } from "./context/presential";

import Home from './pages/Home';
import Login from "./pages/Login";

function App(props) {

  const dataToken = JSON.parse(localStorage.getItem("tokens"));
  const dataPresential = JSON.parse(localStorage.getItem("sessionData"));
  const dataSessionParticipation = localStorage.getItem("sessionParticipation") === null ? [] : JSON.parse(localStorage.getItem("sessionParticipation"));
  const dataPresentialStatus = localStorage.getItem("presentialStatus") === null ? "NOT_CREATED" : localStorage.getItem("presentialStatus");
  const dataConnectedBc = localStorage.getItem("connectedBc") === null ? [] : JSON.parse(localStorage.getItem("connectedBc"));
  const dataCurrentQuestionId = localStorage.getItem("currentQuestionId") === null ? -1 : localStorage.getItem("currentQuestionId");
  const dataCurrentPodiumQuestionId = localStorage.getItem("currentPodiumQuestionId") === null ? -1 : localStorage.getItem("currentPodiumQuestionId");
  const dataQuestionStatus = localStorage.getItem("questionStatus") === null ? "NOT_STARTED" : localStorage.getItem("questionStatus");
  const dataResultForQuestion = localStorage.getItem("resultForQuestion") === null ? "" : localStorage.getItem("resultForQuestion");
  const dataLeaderboardForQuestion = localStorage.getItem("leaderboardForQuestion") === null ? [] : JSON.parse(localStorage.getItem("leaderboardForQuestion"));

  const [authTokens, setAuthTokens] = useState(dataToken);
  const [presentialData, setPresentialData] = useState(dataPresential);
  const [sessionParticipation, setSessionParticipation] = useState(dataSessionParticipation);
  const [presentialStatus, setPresentialStatus] = useState(dataPresentialStatus);
  const [connectedBc, setConnectedBc] = useState(dataConnectedBc);
  const [currentQuestionId, setCurrentQuestionId] = useState(dataCurrentQuestionId);
  const [currentPodiumQuestionId, setCurrentPodiumQuestionId] = useState(dataCurrentPodiumQuestionId);
  const [questionStatus, setQuestionStatus] = useState(dataQuestionStatus);
  const [resultForQuestion, setResultForQuestion] = useState(dataResultForQuestion);
  const [leaderboardForQuestion, setLeaderboardForQuestion] = useState(dataLeaderboardForQuestion);


  const setTokens = (data) => {
    if (data && data !== undefined) {
      localStorage.setItem("tokens", JSON.stringify(data));
    } else {
      resetLocalStorage();
      resetState();
    }
    setAuthTokens(data);
  }

  const resetState = () => {
    localStorage.setItem("presentialStatus", "NOT_CREATED");
    setPresentialStatus("NOT_CREATED");
    setPresentialData(null);
    setConnectedBc([]);
  }

  const resetLocalStorage = () => {
    localStorage.clear();
  }

  useEffect(() => {
    
    const socket = io(config.config.BACKEND_URL, {
      reconnectionDelay: 1000,
      reconnection: true,
      reconnectionAttemps: 10,
      transports: ['polling'],
      agent: false,
      upgrade: false,
      rejectUnauthorized: false
    });

    document.title = "Dior Présentiel - Home";

	console.log("TrainerId:" + authTokens);

    socket.on('admin', function (data) {
      if (data && data.session && data.session.IdFormer === authTokens) {
        console.log("Received1 admin - " + JSON.stringify(data));
        localStorage.removeItem("connectedBc");
        resetState();
        localStorage.setItem("sessionData", JSON.stringify(data));
        setPresentialData(data);
        
        localStorage.setItem("presentialStatus", "WAITING_BC");
        setPresentialStatus("WAITING_BC");
        
        console.log('Writing ' + data.session.SessionParticipation);

        localStorage.setItem("sessionParticipation", JSON.stringify(data.session.SessionParticipation));
        setSessionParticipation(data.session.SessionParticipation);

        window.location.reload();
      }
    });
   
    if (presentialData && presentialData.session && presentialData.session.Id) {
		
    console.log("[App.js] register for redis events");

      socket.on("" + presentialData.session.Id + "_session:update", function(data) {
        console.log("Received " + presentialData.session.Id  + "_session:update + " + data);
      
        var obj = JSON.parse(data);

        if (presentialData.session.SessionParticipation.length < obj.length)
        {
          console.log('There is a new sessionParticipation out there ! Rewrite everything');

          let presentialDataToUpdate = presentialData;
          presentialDataToUpdate.session.SessionParticipation = obj;

          setPresentialData(presentialDataToUpdate);
          localStorage.setItem("sessionData", JSON.stringify(presentialDataToUpdate));

          setSessionParticipation(obj);
          localStorage.setItem("sessionParticipation", JSON.stringify(obj));
        }
      });
		 
      socket.on("" + presentialData.session.Id + "_session:status", function (data) {
        console.log("Received2 " + presentialData.session.Id + "_session:status + " + JSON.stringify(data));
        localStorage.setItem("presentialStatus", data);
        setPresentialStatus(data);
      });

      socket.on("" + presentialData.session.Id + "_session", function (data) {
        console.log("Received " + presentialData.session.Id + "_session");
        if (!connectedBc.includes(data)) {
          let bc = [...connectedBc];
          bc.push(data);
          localStorage.setItem("connectedBc", JSON.stringify(bc));
          setConnectedBc(bc);
        }
      });

      socket.on("" + presentialData.session.Id + "_session:current_question", function (data) {
        console.log("Received3 " + presentialData.session.Id + "_session:current_question");
        localStorage.setItem("currentQuestionId", data);
        setCurrentQuestionId(data);
        
        localStorage.setItem("questionStatus", "INITIAL_START");
        setQuestionStatus("INITIAL_START");

        localStorage.setItem("presentialStatus", "QUESTION_STARTED");
        setPresentialStatus("QUESTION_STARTED");
      });

      socket.on("" + presentialData.session.Id + "_question:stopped", function (data) {
        console.log("Received4 " + presentialData.session.Id + "_question:stopped");
        if (data && data.currentQuestionId) {
          localStorage.setItem("currentQuestionId", data.currentQuestionId);
          setCurrentQuestionId(data.currentQuestionId);
        } else {
          localStorage.removeItem("currentQuestionId");
        }
        if (data && data.answers) {
          localStorage.setItem("resultForQuestion", JSON.stringify(data.answers));
          setResultForQuestion(JSON.stringify(data.answers));
        } else {
          localStorage.removeItem("resultForQuestion");
        }
        localStorage.setItem("questionStatus", "QUESTION_STOPPED");
        setQuestionStatus("QUESTION_STOPPED");

        localStorage.setItem("presentialStatus", "QUESTION_STOPPED");
        setPresentialStatus("QUESTION_STOPPED");
      });

      socket.on("" + presentialData.session.Id + "_session:display:podium:question", function (data) {
        console.log("Received5 " + presentialData.session.Id + "_session:display:podium:question  - QuestionId:" + data.podiumQuestionId);
		
		
        localStorage.setItem("presentialStatus", "DISPLAY_PODIUM_QUESTION");
        setPresentialStatus("DISPLAY_PODIUM_QUESTION");
		//{podiumQuestionId: idQuestion, podium:values}
		
		localStorage.setItem("currentPodiumQuestionId", data.podiumQuestionId);
		setCurrentPodiumQuestionId(data.podiumQuestionId);
		
        localStorage.setItem("leaderboardForQuestion", JSON.stringify(data.podium));
        setLeaderboardForQuestion(data.podium);
      });

      socket.on("" + presentialData.session.Id + "_session:display:podium:global", function (data) {
        console.log("Received6 " + presentialData.session.Id + "_session:display:podium:global");
        localStorage.setItem("presentialStatus", "DISPLAY_PODIUM_GLOBAL");
        setPresentialStatus("DISPLAY_PODIUM_GLOBAL");

        localStorage.setItem("leaderboardForQuestion", JSON.stringify(data));
        setLeaderboardForQuestion(data);
      });
  
    }
	else {
		console.log("no presential data is set");
	}
    return function cleanup() {
		console.log("cleanup : close sockket");
      socket.close();
    }

  }, [authTokens, presentialData, sessionParticipation, presentialStatus, connectedBc, currentQuestionId, questionStatus, resultForQuestion, leaderboardForQuestion, currentPodiumQuestionId]);

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <PresentialContext.Provider value={{ presentialData, sessionParticipation, connectedBc, presentialStatus, currentQuestionId, questionStatus, resultForQuestion, leaderboardForQuestion, setQuestionStatus, currentPodiumQuestionId }}>
        <Router>
          <PrivateRoute exact path="/" component={Home} />
          <Route path="/login" component={Login} />
        </Router>
      </PresentialContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;

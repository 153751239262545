import React from "react";

class AnswerItem extends React.Component {

    render() {
        return (
            <div className="d-flex flex-row justify-content-center align-items-center mt-3">
                <div className="answer-spacer"></div>
                <div className={`${this.props.good ? "answer-background-good" : "answer-background"}`}>
                    <p className="answer-text ml-5">{this.props.questionText}</p>
                </div>
                <div className="answer-spacer answer-participation">{this.props.participation}%</div>
            </div>
        );
    }
}

export default AnswerItem;
import React from "react";

import Header from "../components/Header";
import CardWaiting from "../components/CardWaiting";
import CardBcListing from "../components/CardBcListing";
import CardQuestion from "../components/CardQuestion";
import CardPodium from "../components/CardPodium";
import CardClosed from "../components/CardClosed";
import { usePresential } from "../context/presential";

function Home(props) {

    const { presentialStatus } = usePresential();


    //  return (
    //     <div className="container-fluid h-100 bg-light">
    //         <div className="dior-home">
    //             <Header />
    //             <div className="bg-2 main-container">
    //                 <CardPodium displayQuestion={true} />
    //             </div>
    //         </div>
    //     </div>
    // );

    return (
        <div className="container-fluid h-100 bg-light">
            <div className="dior-home">
                <Header />
                <div className="bg-2 main-container">
                    {presentialStatus === "NOT_CREATED" && <CardWaiting />}
                    {presentialStatus === "WAITING_BC" && <CardBcListing />}
                    {(presentialStatus === "QUESTION_STARTED" || presentialStatus === "QUESTION_STOPPED") && <CardQuestion />}
                    {presentialStatus === "DISPLAY_PODIUM_QUESTION" && <CardPodium displayQuestion={true} />}
                    {presentialStatus === "DISPLAY_PODIUM_GLOBAL" && <CardPodium displayQuestion={false} />}
                    {presentialStatus === "CLOSED" && <CardClosed />}
                </div>
            </div>
        </div>

    );
}

export default Home;
import React from "react";

import { Player } from '@lottiefiles/react-lottie-player';

import animationData from '../animations/WaitingSreenFullAnimationDior.json';

function CardWaiting(props) {

    const style = {
       // width: 'fit-content',
        height: 'calc(100vh - 110px)'
    }

    return (
        <Player
            autoplay
            loop
            src={animationData}
            style={style}
        >
        </Player>

    );
}

export default CardWaiting;
import React from "react";
import { usePresential } from "../context/presential";
import { Player } from '@lottiefiles/react-lottie-player';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import ParticipationLogo from '../img/ParticipationBC_Icon.svg';
import animationData from '../animations/FondLoopDior.json';

function CardBcListing() {

    const { connectedBc, sessionParticipation } = usePresential();

    const style = {
        width: 'fit-content',
        height: 'calc(100vh - 110px)'
    }

    return (
        <div className="d-flex">
            <Player
                autoplay
                loop
                src={animationData}
                style={style}
            >
            </Player>

            <div id="participation">
                <div className="row justify-content-center">
                    <h2>Participation</h2>
                </div>
                <div className="row mt-2">
                    <div className="col text-center">
                        <img src={ParticipationLogo} width='100' alt="" />
                        <h3 className="h3-dior mt-4">{connectedBc.length} / {sessionParticipation.filter(x => x.IdPlayer !== null).length} Participants</h3>

                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col text-center ">
                        <div className="white-bar">
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col text-center">
                        <CircularProgressbar
                            value={connectedBc.length / sessionParticipation.filter(x => x.IdPlayer !== null).length * 100}
                            text={`${sessionParticipation.filter(x => x.IdPlayer !== null).length !== 0 ? Math.round(connectedBc.length / sessionParticipation.filter(x => x.IdPlayer !== null).length * 100) : 0}%`}
                            styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0,

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'butt',

                                // Text size
                                textSize: '16px',

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                pathColor: `#e880a8`,
                                textColor: '#e880a8',
                                trailColor: '#ffffff',
                                backgroundColor: '#FFFFFF',
                            })}
                        />
                    </div>
                </div>

            </div>
        </div>


    );
}

export default CardBcListing;
import React from "react";
import * as ReactBootsrap from 'react-bootstrap';

function ConfirmLogoutModal(props) {
    return (
        <ReactBootsrap.Modal show={props.show} onHide={props.handleClose} animation={false}>
            <ReactBootsrap.Modal.Header>
                <ReactBootsrap.Modal.Title>Déconnexion</ReactBootsrap.Modal.Title>
            </ReactBootsrap.Modal.Header>

            <ReactBootsrap.Modal.Body>Voulez-vous vous déconnecter ?</ReactBootsrap.Modal.Body>
                <ReactBootsrap.Modal.Footer>
                <button type="button" className="btn-custom primary" onClick={props.handleLogout}>Oui</button>
                <button type="button" className="btn-custom secondary" onClick={props.handleClose}>Non</button>
              </ReactBootsrap.Modal.Footer>
        </ReactBootsrap.Modal>
    );
}

export default ConfirmLogoutModal;
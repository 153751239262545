import React, { useEffect, useState } from "react";
import { Player } from '@lottiefiles/react-lottie-player';

import animationPodium1 from '../animations/PodiumDiorRank1.json';
import animationPodium2 from '../animations/PodiumDiorRank2.json';
import animationPodium3 from '../animations/PodiumDiorRank3.json';

import { usePresential } from "../context/presential";
import audio from "../audio/2154-triangle-2.mp3";
import audioGeneral from "../audio/6073-rythme-8.mp3";

function CardPodium(props) {

    const { presentialData, leaderboardForQuestion, currentPodiumQuestionId, sessionParticipation } = usePresential();
    const [showPodium3, setShowPodium3] = useState(false);
    const [showPodium2, setShowPodium2] = useState(false);
    const [showPodium1, setShowPodium1] = useState(false);
    const [playerPodium3] = useState(React.createRef());
    const [playerPodium2] = useState(React.createRef());
    const [playerPodium1] = useState(React.createRef());

    const PLAYER_NAME_MAX_LENGTH = 25;

    var player1 = leaderboardForQuestion.length > 0 ? sessionParticipation.find(x => x.IdPlayer && leaderboardForQuestion[0] && parseInt(x.IdPlayer) === parseInt(leaderboardForQuestion[0])) : null;
    var player2 = leaderboardForQuestion.length > 1 ? sessionParticipation.find(x => x.IdPlayer && leaderboardForQuestion[1] && parseInt(x.IdPlayer) === parseInt(leaderboardForQuestion[1])) : null;
    var player3 = leaderboardForQuestion.length > 2 ? sessionParticipation.find(x => x.IdPlayer && leaderboardForQuestion[2] && parseInt(x.IdPlayer) === parseInt(leaderboardForQuestion[2])) : null;

    var player1LastName = (player1 && player1?.LastName != null && player1?.LastName !== "") ? player1?.LastName.substring(0, 1) : "";
    var player2LastName = (player2 && player2?.LastName != null && player2?.LastName !== "") ? player2?.LastName.substring(0, 1) : "";
    var player3LastName = (player3 && player3?.LastName != null && player3?.LastName !== "") ? player3?.LastName.substring(0, 1) : "";

    useEffect(() => {

        setTimeout(() => {
            setShowPodium3(true);
        }, 2000);

        setTimeout(() => {
            if (playerPodium3 && playerPodium3.current) {
                playerPodium3.current.pause();
            }
        }, 2500);

        setTimeout(() => {
            if (!showPodium2) {
                if (playerPodium2 && playerPodium2.current) {
                    playerPodium2.current.play();
                }
            }
        }, 3000);

        setTimeout(() => {
            setShowPodium2(true);
            if (playerPodium2 && playerPodium2.current) {
                playerPodium2.current.pause();
            }
        }, 5200);

        setTimeout(() => {
            if (!showPodium1) {
                if (playerPodium1 && playerPodium1.current) {
                    playerPodium1.current.play();
                }
            }
        }, 5000);

        setTimeout(() => {
            setShowPodium1(true);
        }, 8000);

        setTimeout(() => {
            if (playerPodium1 && playerPodium1.current) {
                playerPodium1.current.pause();
            }
        }, 8300);

    }, [showPodium3, showPodium2, showPodium1, playerPodium3, playerPodium2, playerPodium1]);

    let player1Html = "";
    let player1Sound = "";
    if (showPodium1) {
        player1Html = <h2 className="top-1">{player1 ? `${player1?.FirstName} ${player1LastName}`.substring(0, PLAYER_NAME_MAX_LENGTH) : ''}</h2>;
        if (props.displayQuestion) {
            player1Sound = <audio controls hidden autoPlay><source src={audio} type="audio/mp3" /></audio>;
        }
    }

    let player2Html = "";
    let player2Sound = "";
    if (showPodium2) {
        player2Html = <h2 className="top-2">{player2 ? `${player2?.FirstName} ${player2LastName}`.substring(0, PLAYER_NAME_MAX_LENGTH) : ''}</h2>;
        if (props.displayQuestion) {
            player2Sound = <audio controls hidden autoPlay><source src={audio} type="audio/mp3" /></audio>;
        }
    }

    let player3Html = "";
    let player3Sound = "";
    if (showPodium3) {
        player3Html = <h2 className="top-3">{player3 ? `${player3?.FirstName} ${player3LastName}`.substring(0, PLAYER_NAME_MAX_LENGTH) : ''}</h2>;
        if (props.displayQuestion) {
            player3Sound = <audio controls hidden autoPlay><source src={audio} type="audio/mp3" /></audio>;
        }
    }

    console.log("Podium. CurrentPodiumQuestionId:" + currentPodiumQuestionId);
    if (typeof (currentPodiumQuestionId) == "undefined") {
        console.warn("Podium. Failed to retrieve CurrentPodiumQuestionId");
        //currentPodiumQuestionId = currentQuestionId;
    }

    let question = props.displayQuestion ? presentialData?.superlovers.Items.filter(x => x.Type === 'QUESTION' && x.Question.Id === currentPodiumQuestionId)[0]?.Question : null;

    return (
        <div className="h-100">
            <div className="ml-15 mr-15">
                <div className="row">
                    <div className="col mt-5 text-center">
                        <h2>{props.displayQuestion ? `Question` : `Podium`}</h2>
                    </div>
                </div>

                {props.displayQuestion &&
                    <div className="row">
                        <div className="col mt-4 text-center">
                            <p className="text-question">{presentialData?.superlovers.Items.filter(x => x.Type === 'QUESTION' && x.Question.Id === currentPodiumQuestionId)[0]?.Question.Text}</p>
                            {question?.Answers.filter(x => x.IsTrue).length > 1 && <p className="text-multiple-good-answers">Plusieurs réponses possibles</p>}
                        </div>
                    </div>
                }

                {
                    !props.displayQuestion && showPodium3 &&
                    <audio controls hidden autoPlay>
                        <source src={audioGeneral} type="audio/mp3" />
                    </audio>
                }

                <div className="row">
                    <div className="col mt-5 text-center">
                        <div className="white-bar">
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col mt-5 text-center">
                        <div className="row">
                            {player3Html}
                            {player3Sound}
                            <Player
                                ref={playerPodium3}
                                src={animationPodium3}
                                autoplay
                            />
                        </div>
                    </div>

                    <div className="col mt-5 text-center">
                        <div className="row">
                            {player2Html}
                            {player2Sound}

                            <Player
                                ref={playerPodium2}
                                src={animationPodium2}
                            />
                        </div>
                    </div>

                    <div className="col mt-5 text-center">
                        <div className="row">
                            {player1Html}
                            {player1Sound}
                            <Player
                                ref={playerPodium1}
                                src={animationPodium1}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CardPodium;
import React, { useState, useEffect } from "react";
import { Player } from '@lottiefiles/react-lottie-player';
import { usePresential } from "../context/presential";
import AnswerItem from './AnswerItem';

import animationData from '../animations/CountdownDior.json';
import animationData5 from '../animations/CountdownDior-5sec.json';
import audio from "../audio/countdown-rercus-20.mp3";

function CardQuestion() {

    const { currentQuestionId, presentialData, questionStatus, resultForQuestion, setQuestionStatus, sessionParticipation } = usePresential();
    const [isStarted, setIsStarted] = useState(false);
    const [playerCountdown] = useState(React.createRef());
	const [playerCountdown5] = useState(React.createRef());

    const [displayAnswersTimeout, setDisplayAnswersTimeout] = useState(null);
    
    useEffect(() => {

        if (questionStatus === "INITIAL_START") {
			console.log("isStarted:" + isStarted);
			setTimeout(function () {
            if (!isStarted && playerCountdown5 && playerCountdown5.current) {
				console.log("Start animationData5");
                playerCountdown5.current.play();
            }}, 100);
			
			setTimeout(function () {
				
                setIsStarted(true);
                if (playerCountdown && playerCountdown.current) {
					console.log("Start animationData60");
                    playerCountdown.current.play();
                }
            }, 5000);

            if (displayAnswersTimeout == null)
            {
                setDisplayAnswersTimeout(setTimeout(() => {
                    if (isStarted) { //Security : if we didn't receive the signal that the question is stopped, we force the set of the question status to STOPPED
                        setQuestionStatus("QUESTION_STOPPED");
                    }
                }, 65000));
            }
        }

        if (questionStatus === "QUESTION_STOPPED") {
            //We need to reset variables for display the next question (there is no refresh of the page, so all variables keep their states)
            setIsStarted(false);

            clearTimeout(displayAnswersTimeout);
            setDisplayAnswersTimeout(null);
        }

    }, [questionStatus, isStarted, playerCountdown, playerCountdown5, displayAnswersTimeout, setQuestionStatus]);


    const style = {
        height: '390px',
        padding: '10px'
    }

    let question = presentialData?.superlovers.Items.filter(x => x.Type === 'QUESTION' && x.Question.Id === currentQuestionId)[0].Question;
    let hasOnlyOneGoodAnswer = question?.Answers.filter(x => x.IsTrue).length === 1;

    return (
        <div className="h-100">
            <div className="ml-15 mr-15">
                <div className="row">
                    <div className="col mt-3 text-center">
                        <h2>Question</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col mt-2 text-center">
                        <p className="text-question">{question?.Text}</p>
                        {!hasOnlyOneGoodAnswer && <small className="text-multiple-good-answers">Plusieurs réponses possibles</small>}
                    </div>
                </div>

                <div className="row">
                    <div className="col mt-2 text-center">
                        <div className="white-bar">
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col mt-5">
                        {!isStarted && questionStatus === "INITIAL_START" &&
                            <div className="time-wrapper text-center">
                                 <audio controls hidden autoPlay>
                                    <source src={audio} type="audio/mp3"/>
                                </audio>
                                <Player
                                    ref={playerCountdown5}
                                    src={animationData5}
                                    style={style}
                                />
                            </div>}
							
						{isStarted && questionStatus === "INITIAL_START" &&
                            <div className="time-wrapper text-center">
                                 <audio controls hidden autoPlay>
                                    <source src={audio} type="audio/mp3"/>
                                </audio>
                                <Player
                                    ref={playerCountdown}
                                    src={animationData}
                                    style={style}
                                />
                            </div>}
                        {questionStatus === "QUESTION_STOPPED" &&
                            <div>
                                <h2 className="h2-dior">{hasOnlyOneGoodAnswer ? "La bonne réponse était" : "Les bonnes réponses étaient"}</h2>
                                <div className="answer-parent mt-2">
                                {question?.Answers.map((answer, i) => {
                                        
                                        let participation = 0;

                                        if (resultForQuestion)
                                        {
                                            var data = JSON.parse(resultForQuestion);
                                            let totalThatAnsweredQuestions = Object.keys(data).length;

                                            var total = 0;
                                            for (var idPlayer in data) {
                                                if (data[idPlayer].includes(answer.Id)) {
                                                    total++;
                                                }
                                            }

                                            if (sessionParticipation.filter(x => x.IdPlayer !== null).length !== 0)
                                            {
                                                if (totalThatAnsweredQuestions > 0)
                                                    participation = Math.round((total / totalThatAnsweredQuestions) * 100);
                                                else
                                                    participation = 0;
                                            }
                                        }
                                        
                                        return (<AnswerItem good={answer.IsTrue} key={i} questionText={answer.Text} participation={participation} />)
                                    })}

                                </div>
                            </div>
                        }

                    </div>
                </div>

            </div>
        </div>
    );
}

export default CardQuestion;
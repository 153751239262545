const dev = {
    BACKEND_URL: "http://localhost:8443"
}

const integration = {
    BACKEND_URL: "https://presential-preprod.manzalab.com:8443"
}

const prod = {
    BACKEND_URL: "https://presential-dior.manzalab.com:8443",
}

const config = (process.env.REACT_APP_STAGE === 'production' ? prod : (process.env.REACT_APP_STAGE === 'integration' ? integration : dev));

export default {
    config
};
import React, { useState } from "react";
import * as ReactBootsrap from 'react-bootstrap';
import { useAuth } from "../context/auth";
import { usePresential } from "../context/presential";
import ConfirmLogoutModal from "../modals/ConfirmLogoutModal";

import SuperLoverLogo from '../img/SuperLovers_Logo.svg';
import LogoutIcon from '../img/Disconnection_Button.svg';

function Header(props) {

    const [show, setShow] = useState(false);
    const { setAuthTokens } = useAuth();
    const { presentialData } = usePresential();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function logOut() {
        setAuthTokens();
    }

    return (
        <ReactBootsrap.Navbar bg="light" expand="lg">
            <ReactBootsrap.Navbar.Brand>
                <img src={SuperLoverLogo} height="50" alt=""/> <h1 className="h1-dior">{presentialData?.session.NameSuperLover}</h1>
                </ReactBootsrap.Navbar.Brand>
            <ReactBootsrap.Nav className="mr-auto">
            </ReactBootsrap.Nav>
            <ReactBootsrap.Form inline>
                <img src={LogoutIcon} height="40" onClick={handleShow} alt="" />
            </ReactBootsrap.Form>
            <ConfirmLogoutModal show={show} handleClose={handleClose} handleLogout={logOut}></ConfirmLogoutModal>
        </ReactBootsrap.Navbar>
    );
}

export default Header;
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import * as ReactBootsrap from 'react-bootstrap';

import { Player } from '@lottiefiles/react-lottie-player';
import animationData from '../animations/Bulle-Ecran-LogIn-Dior-Presentiel.json';

import logoImg from "../img/logo.png";
import { useAuth } from "../context/auth";
import config from "../config/config";

function Login(props) {

    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const { setAuthTokens } = useAuth();
    const referer = props.location.state.referer || '/';

    useEffect(() => {
        document.title = "Dior Présentiel - Login"
    }, []);

    async function postLogin() {
        setIsLoading(true);
        const result = await fetch(config.config.BACKEND_URL + "/login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: userName,
                password: password
            }),
        });
        if (result.status === 200) {
            const json = await result.json();
            setAuthTokens(json.uid);
            setLoggedIn(true);
            setIsLoading(false);
        } else {
            console.log(result);
            setIsError(true);
            setIsLoading(false);
        }
    }

    function formPreventDefault(e) {
        e.preventDefault();
    }

    if (isLoggedIn) {
        return <Redirect to={referer} />;
    }

    return (
        <div className="container-fluid h-100">
            <div className="row h-100">
                <div className="col">
                    <div className="h-100 d-flex flex-column">
                        <div className="row justify-content-center bg-1 flex-grow-1 vertical-align-content">
                            <Player
                                autoplay
                                loop
                                src={animationData}
                            >
                            </Player>
                        </div>
                    </div>
                </div>
                <div className="col col-lg-3 shadow p-3 bg-white h-100">
                    <div className="row justify-content-center mt-5">
                        <img src={logoImg} width='30%' alt=""/>
                    </div>
                    <div className="row mt-5">
                        <div className="col ml-5 mr-5">
                            <form onSubmit={formPreventDefault}>
                                <div className="form-group" controlid="formBasicEmail">
                                    <label>Identifiant formatrice</label>
                                    <input className="form-control dior-control" type="email" required value={userName} onChange={e => {
                                        setUserName(e.target.value);
                                    }}></input>
                                </div>

                                <div className="form-group" controlid="formBasicPassword">
                                    <label>Mot de passe</label>
                                    <input className="form-control dior-control" type="password" required value={password} onChange={e => {
                                        setPassword(e.target.value);
                                    }}></input>
                                </div>

                                <button className="btn-custom dior-btn mt-5" type="submit" onClick={postLogin}>SE CONNECTER</button>
                                {isLoading &&
                                    <div className="d-flex mt-3">
                                        <ReactBootsrap.Spinner animation="border" role="status" className="dior-spinner">
                                        </ReactBootsrap.Spinner>
                                        <label className="ml-3">Chargement ...</label>
                                    </div>
                                }

                                <div className="form-group mt-3" controlid="formBasicAlert">
                                    {isError && <ReactBootsrap.Alert variant="danger">Les identifiants sont incorrects</ReactBootsrap.Alert>}
                                </div>

                            </form>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;